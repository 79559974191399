@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsLight.ttf");
  font-family: "Poppins";
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsRegular.ttf");
  font-family: "Poppins";
  font-weight: 400;
}
